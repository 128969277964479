<template>
  <div class="flex flex-col text-left">
    <tabs v-model="tab" :tabs="menu" />

    <!-- Tab 1 -->
    <div v-if="!tab" class="flex flex-col justify-items-stretch w-full mt-2">
      <div class="my-4 w-full md:w-1/3">
        <div class="flex flex-col space-y-2 my-2">
          <label for="" class="text-lg text-blue-dark">Name</label>
          <input type="text" class="border rounded px-2 py-1" v-model="display_name" />
        </div>
      </div>

      <div class="flex text-xs md:text-xl">
        <div class="h-full w-1/2 border min-h-96 max-h-961 overflow-y-auto">
          <ul class="flex flex-col space-y-2">
            <li
              v-for="(user, index) in usersList"
              :key="`user-${index}`"
              class="px-2 py-2 border shadow-sm flex flex-col"
              @click.prevent="shift(index, 'right')"
            >
              <p class="">{{ user.first_name }} {{ user.last_name }}</p>
              <p>{{ user.email }}</p>
            </li>
          </ul>
        </div>
        <div class="self-center flex justify-center mx-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
            />
          </svg>
        </div>
        <div class="border w-1/2 min-h-96 max-h-961 overflow-y-auto">
          <ul class="flex flex-col space-y-2">
            <li
              v-for="(user, index) in usersSelected"
              :key="`user-${index}`"
              class="px-2 py-2 border shadow-sm flex flex-col"
              @click.prevent="shift(index, 'left')"
            >
              <p class="text-xl">{{ user.first_name }} {{ user.last_name }}</p>
              <p>{{ user.email }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Tab 1 -->

    <!-- Tab 2 -->
    <div v-if="tab" class="flex justify-items-stretch space-x-4 w-full mt-2">
      <div class="w-full border min-h-96 max-h-961 overflow-y-auto p-2">
        <ul class="flex flex-col w-full">
          <div>
            <div class="flex py-1 mb-3 text-blue-dark font-semibold">
              <div class="w-1/2 md:w-1/4"></div>
              <div class="w-1/2 md:w-3/4">
                <table class="w-full">
                  <tr class="flex justify-between">
                    <td width="10%"></td>

                    <td width="45%" class="border text-center text-base">Ansicht</td>

                    <td width="45%" class="border text-center text-base">Editieren</td>
                  </tr>
                  <tr class="flex justify-between">
                    <td width="10%" class="border text-center">Aktiv</td>

                    <td width="45%">
                      <table class="w-full">
                        <tr>
                          <td class="border text-center" width="10%">Alle</td>
                          <td class="border text-center" width="50%">
                            nur von einer Organisationseinheit
                          </td>
                          <td class="border text-center" width="30%">nur eigene Daten</td>
                        </tr>
                      </table>
                    </td>

                    <td width="45%">
                      <table class="w-full">
                        <tr>
                          <td class="border text-center" width="10%">Alle</td>
                          <td class="border text-center" width="50%">
                            nur von einer Organisationseinheit
                          </td>
                          <td class="border text-center" width="30%">nur eigene Daten</td>
                          <td class="border text-center" width="10%">Keine</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <li v-for="(values, key, index) in permissions" :key="`user-${index}`" class="w-full">
            <div class="flex flex-col space-y-4">
              <div class="flex flex-col my-3">
                <div class="flex py-1 mb-3 text-blue-dark font-semibold">
                  <div class="w-1/2 md:w-1/4">
                    {{ groups[key] }}
                  </div>
                  <div v-if="!index" class="w-1/2 md:w-3/4"></div>
                </div>

                <div class="flex flex-col space-y-4">
                  <template v-if="Array.isArray(values)">
                    <template v-for="(value, cindex) in values">
                      <div class="flex" :key="`permissions-${index}-${cindex}`">
                        <div class="w-1/2 md:w-1/4 pl-4">{{ value['f-label'] }}</div>

                        <div class="w-1/2 md:w-3/4">
                          <table class="w-full">
                            <tr class="flex justify-between">
                              <td width="10%" class="text-center">
                                <input
                                  type="checkbox"
                                  class="form-checkbox h-4 w-4"
                                  v-model="permissions[key][cindex]['active']"
                                />
                              </td>

                              <td width="45%">
                                <table class="w-full">
                                  <tr>
                                    <td class="text-center" width="10%">
                                      <input
                                        :disabled="!permissions[key][cindex]['active']"
                                        type="radio"
                                        class="form-radio h-4 w-4"
                                        v-model="permissions[key][cindex]['view']"
                                        value="All"
                                      />
                                    </td>
                                    <td class="text-center" width="50%">
                                      <input
                                        :disabled="!permissions[key][cindex]['active']"
                                        type="radio"
                                        class="form-radio h-4 w-4"
                                        v-model="permissions[key][cindex]['view']"
                                        value="Only own unit data"
                                      />
                                    </td>
                                    <td class="text-center" width="30%">
                                      <input
                                        :disabled="!permissions[key][cindex]['active']"
                                        type="radio"
                                        class="form-radio h-4 w-4"
                                        v-model="permissions[key][cindex]['view']"
                                        value="Only own personal data"
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </td>

                              <td width="45%">
                                <table class="w-full">
                                  <tr>
                                    <td class="text-center" width="10%">
                                      <input
                                        :disabled="!permissions[key][cindex]['active']"
                                        type="radio"
                                        class="form-radio h-4 w-4"
                                        v-model="permissions[key][cindex]['edit']"
                                        value="All"
                                      />
                                    </td>
                                    <td class="text-center" width="50%">
                                      <input
                                        :disabled="!permissions[key][cindex]['active']"
                                        type="radio"
                                        class="form-radio h-4 w-4"
                                        v-model="permissions[key][cindex]['edit']"
                                        value="Only own unit data"
                                      />
                                    </td>
                                    <td class="text-center" width="30%">
                                      <input
                                        :disabled="!permissions[key][cindex]['active']"
                                        type="radio"
                                        class="form-radio h-4 w-4"
                                        v-model="permissions[key][cindex]['edit']"
                                        value="Only own personal data"
                                      />
                                    </td>
                                    <td class="text-center" width="10%">
                                      <input
                                        :disabled="!permissions[key][cindex]['active']"
                                        type="radio"
                                        class="form-radio h-4 w-4"
                                        v-model="permissions[key][cindex]['edit']"
                                        value="None"
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Tab 2 -->
  </div>
</template>

<script>
import _ from 'lodash'
import { xhr } from '@/utils/axios'
import Tabs from '@/components/TabCard/Tabs'

export default {
  name: 'new-groups-and-permissions',
  props: ['payload'],
  components: {
    Tabs,
  },
  data() {
    return {
      tab: 0,
      menu: ['Allgemein', 'Berechtigungen'],
      display_name: '',
      users: [],
      usersList: [],
      usersSelected: [],
      permissions: [],
      localPermissions: [],
      groups: {
        users: 'Benutzer und Gruppenverwaltung',
        personal: 'Personal verwalten',
        performance_evaluation: 'Mitarbeitergespräche',
        organisation: 'Organisation',
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getPermissions()
    },
    shift(index, dir) {
      if (dir === 'right') {
        this.usersSelected.push(this.usersList[index])
        this.usersList.splice(index, 1)
      } else {
        this.usersList.push(this.usersSelected[index])
        this.usersSelected.splice(index, 1)
      }
    },
    getGroup(id) {
      xhr.get(`/users/groups/${id}`).then(response => {
        if (response.status === 200) {
          this.display_name = response.data.display_name
          this.usersSelected = response.data.user_set
          this.usersList = response.data.available_users
          this.permissions = response.data.model_permissions
        }
      })
    },
    getUsers() {
      xhr.get('/users/').then(response => {
        if (response.status === 200) {
          this.users = response.data.results
          this.usersList = response.data.results
        }
      })
    },
    getPermissions() {
      xhr.get('/users/permissions/').then(response => {
        if (response.status === 200) {
          delete response.data.data.all_users

          this.permissions = response.data.data
        }
      })
    },
    updatePermissions() {
      // this.permissions
      // this.localPermissions[cindex] = {
      //   app_label: key,
      //   model: this.permissions[key][cindex].model,
      //   active: $event.target.checked,
      //   view: this.permissions[key][cindex].view,
      //   edit: this.permissions[key][cindex].edit,
      // }
    },
    save() {
      this.updatePermissions()
      const payload = {
        display_name: this.display_name,
        user_set: _.map(this.usersSelected, 'id'),
        model_permissions: this.permissions,
      }

      if (this.payload && this.payload.id) {
        xhr
          .patch(`/users/groups/${this.payload.id}/`, payload, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(() => {
            this.$emit('close-modal', { noRedirect: true })
          })
      } else {
        xhr
          .post(`/users/groups/`, payload, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(() => {
            this.$emit('close-modal', { noRedirect: true })
          })
      }
    },
  },
  watch: {
    payload: {
      handler: function (n) {
        if (n && n.id) this.getGroup(n.id)
        else {
          this.getUsers()
        }
      },
      immediate: true,
    },
  },
}
</script>
