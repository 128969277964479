<template>
  <div class="flex justify-items-start w-full mb-2">
    <button v-show="show" @click.prevent="() => updateHandler(-1)" class="border rounded-sm">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <nav ref="tabscroll" class="tabs flex overflow-x-scroll flex-row space-x-2 text-base mx-2">
      <button
        v-for="(tab, index) in tabs"
        :key="`tab-${index}`"
        :id="`tab-${index}`"
        class="flex-none px-2 pt-1 text-sm hover:text-blue-light focus:outline-none text-blue-dark font-semibold border-b-2 border-transparent snap-center"
        :class="index === value && 'border-blue-dark'"
        @click.prevent="() => clickHandler(index)"
      >
        {{ tab }}
      </button>
    </nav>

    <button v-show="show" @click.prevent="() => updateHandler(1)" class="ml-auto border rounded-sm">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  props: {
    value: {
      required: true,
    },
    tabs: {
      required: true,
    },
    show: {
      default: false,
    },
  },
  components: {},
  data() {
    return {
      current: this.value || 0,
    }
  },
  methods: {
    clickHandler(index) {
      this.current = index
      this.$emit('input', this.current)
    },
    updateHandler(step) {
      if (step === 1 && this.current < this.tabs.length - 1) {
        this.current = this.current + step
      }

      if (step === -1 && this.current > 0) {
        this.current = this.current + step
      }

      const elem = document.getElementById(`tab-${this.current}`)
      const offset = elem.scrollWidth * this.current

      this.$refs.tabscroll.scrollTo({
        left: offset,
        behavior: 'smooth',
      })

      this.$emit('input', this.current)
    },
    scrollToElement(options) {
      const el = this.$el.getElementsByClassName('index-50')[0]

      if (el) {
        el.scrollIntoView(options)
      }
    },
  },
}
</script>

<style></style>
